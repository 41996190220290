<template>
  <div class="wrapper">
    <div class="content">
      <div class="contactForm">
        <div class="text">
          <h1 style="margin-top: 0px;">Contacto</h1>
          <p>
            Si tienes alguna sugerencia, pregunta o queja no dudes en
            contactarnos. Tu feedback nos será muy útil para crecer y dar un
            mejor servicio.
          </p>
        </div>
        <div class="form">
            <input type="text" name="" id="" placeholder="nombre" v-model="contact.name">
            <input type="text" name="" id="" placeholder="e-mail" v-model="contact.email">
            <textarea name="" id="" cols="30" rows="10" placeholder="mensaje"  v-model="contact.message">
                
            </textarea>
            <button style="margin-top: 20px;" @click="sendMessage()">Enviar</button>
            <div v-if="submitted" class="submitted">
              Gracias, por su mensaje!
            </div>
            <!-- <img src="@/assets/images/contact/wavy_background_CONTACT_FORM.svg" alt=""> -->
        </div>
      </div>
      <div>
        <h2>Atención al cliente</h2>
        <div class="business-cards">
          <div class="card">
            <!-- <div class="name">ANGELES GARCÍCA SERNA</div> -->
            <div class="contact">
              <div class="icon"><img src="@/assets/images/contact/email_address_icon.svg" alt=""></div>
              <div class="text">info@vvmovil.com</div>
            </div>
            <div class="contact">
              <div class="icon"><img src="@/assets/images/contact/phone_no_icon.svg" alt=""></div>
              <div class="text">+52 811 059 0916</div>
            </div>
          </div>
   
        </div>
      </div>
      <!-- <h2></h2> -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Contact",
  mounted() {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  },
  data(){
    return{
      contact: {
        name: null,
        email: null,
        message: null,
      },
      submitted: false
    }
  },
  methods: {
    sendMessage(){
      if(this.contact.name && this.contact.email && this.contact.message) {
        let config = {
          headers: {
            'Content-Type': 'application/json',
            'X-Parse-Application-Id': 'ZTrVhcBGYLcr5qe37CPNNKSVVYEkXwWGOs5IPJd5',
            'X-Parse-REST-API-Key': 'KmQQCUVtyRNZwfI1jfSgZwE0p53VJ0zhI5yCqxHb'
          }
        }
        axios.post(
          'https://parseapi.back4app.com/classes/Feedback',
          this.contact,
          config
        ).then( () => {
          this.submitted = true
          this.contact= {
            name: null,
            email: null,
            message: null,
          }
        })
        .catch((err) => {alert("Error - Message not sent"), console.log(err)})   
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/styles/variables";
    .contactForm{
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: 800px) {
         display: block;
        }
        .text{
            width: 40%;
            text-align: left;
            @media screen and (max-width: 800px) {
         width: 100%;
        }
        }
        .form{
            width: 55%;
              @media screen and (max-width: 800px) {
         width: 100%;
        }
            input{
                width: calc(100% - 30px);
    background-color: $gray-light;
    border-radius: 30px;
    border: none;
    outline: none;
    padding: 15px;
    margin-bottom: 15px;
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $blue-light;
    opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $blue-light;
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
    color: $blue-light;
    }
    button{
      margin-top: 20px;
    }
}
textarea{
     width: calc(100% - 30px);
    
    background-image: url('../assets/images/contact/wavy_background_CONATCT_FORM.svg');
    background-repeat: no-repeat;
    background-position-y: 60px;
    background-position-x: right;
    background-size: 270px 160px;
    background-color: $gray-light;
    border-radius: 15px;
    padding: 15px;
    border: none;
    resize: none;
    outline: none;
    font-family: inherit;
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $blue-light;
    opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $blue-light;
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
    color: $blue-light;
    }
    }
    .submitted{
      color: $red;
      margin-top: 15px;
      font-weight:600;
    }
        }
    }
    .business-cards{
      display: flex;
      justify-content: space-evenly;
      margin-bottom: 100px;
      @media screen and (max-width: 800px) {
         display: block;
        }
      .card{
        width: 300px;
        text-align: left;
        margin-bottom: 30px;
         display: flex;
        //  text-align: center;
             flex-direction: column;
        align-items: center;
        @media screen and (max-width: 800px) {
         width: fit-content;
         margin:auto;
         margin-bottom: 30px;
        //  display: flex;
        //  text-align: center;
        //      flex-direction: column;
        // align-items: center;
    
        }
        .name{
          color: $blue-light;
          margin-bottom: 30px;
        }
        .contact{
          display: flex;
          .icon{
            width: 25px;
          }
          .text{
            line-height: 25px;
            vertical-align: middle;
            margin-left: 10px;
          }
        }
      }
    }
</style>
